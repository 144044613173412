import 'core-js';
import 'whatwg-fetch';
import { TextEncoder, TextDecoder } from 'text-encoding';

if (typeof document === 'undefined') {
    global.document = {
        createElement: () => ({} as HTMLElement),
        getElementById: () => null,
    } as unknown as Document;
}

global.TextEncoder = TextEncoder;
global.TextDecoder = TextDecoder;

if (typeof queueMicrotask === 'undefined') {
    global.queueMicrotask = require('queue-microtask');
}
